import { StateApi } from 'services/state-api';
import { SicAlert, BusinessMap, Detection, DetectionsApiClient } from '../../../../../services/cyber-api';
import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { Toast } from 'utilities/toast';
import { Utilities } from 'utilities/utilities';

@autoinject
export class ThreatRespond {
    @bindable() public threat: SicAlert;
    @bindable() public businessMap: BusinessMap;
    @bindable() public fleetVessels: BusinessMap[];
    public detections: Detection[];
    public error: string;
    private readonly requiredXchangeFirmwareVersion: string = '5.1';

    constructor(
        private detectionsApi: DetectionsApiClient,
        private state: StateApi
    ) {
    }

    private async businessMapChanged(): Promise<void> {
        // Reset before retreiving new data
        this.error = undefined;
        this.detections = undefined;

        await this.retrieveDetections();
    }

    @computedFrom('businessMap')
    private get xchangeCompatibleHardware(): boolean {
        if (!this.businessMap.xChangeFirmwareVersion) return undefined;
        // return false;

        // #16695 [XC-CM] Do not display "Block on this vessel" button if XChange firmware is <5.1
        // note: baffled that this comparison works! Javascript is... special
        // '5.3.0' > '5.2' true
        // '5.3.0' < '5.2' false
        // '5.3.0' < '5.2.1.3' false
        // '5.3.0' > '5.2.1.3' true
        return this.businessMap.xChangeFirmwareVersion >= this.requiredXchangeFirmwareVersion;
    }

    private async retrieveDetections(): Promise<void> {
        try {
            // When XChange compatible hardware is detected, lookup any (actionable) detections a.k.a. countermeasures through the api
            this.detections = this.xchangeCompatibleHardware
                ? await this.detectionsApi.byThreatShortId(this.threat.shortId, this.state.company())
                : this.detections = [];

        } catch (error) {
            // A problem occurred when retrieving detections for this threat, notify the user with an alert containing
            // the error message.
            let errorMessage: string;
            errorMessage = error['message'];

            // Replace presumably present '[NotFound]' from error message
            errorMessage = errorMessage.replace('[NotFound] ', '');

            const errorMessageJson = JSON.parse(errorMessage);
            if (errorMessageJson && 'message' in errorMessageJson)
                errorMessage = errorMessageJson['message'];

            this.error = errorMessage || 'An unknown error occurred.';
            return;
        }
    }

    private copy(value: string): void {
        Utilities.copyToClipboard(value);

        Toast.info('Copied to clipboard');
    }
}
