import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { BoxResponse, BusinessMap, DetectionsApiClient, XchangeApiClient } from 'services/cyber-api';
import { StateApi } from '../../../../../services/state-api';
import { environment } from '../../../../../utilities/environment';
import { Toast } from '../../../../../utilities/toast';

@autoinject()
export class ThreatSiteConfiguration {
    @bindable() private businessMap: BusinessMap;
    private cyberLogsActive = 'Not Activated';
    private dnsBlacklistDisplay = 'Not Activated';
    private environment = environment;

    constructor(
        private xchangeApi: XchangeApiClient,
        private state: StateApi
    ) {
    }

    private businessMapChanged() {
        if (!this.businessMap.xChangeFirmwareVersion) return undefined;
        // Retrieve XChange Box information
        this.xchangeApi.box(this.businessMap.xchangeBoxSerialNo, this.state.company())
            .then((boxResponse) => {
                this.cyberLogsActive = boxResponse.isCyberLogsFeatureActivated === true
                    ? 'Activated'
                    : 'Not Activated';
                this.dnsBlacklistDisplay = boxResponse.isDnsBlacklistingFeatureActivated === true
                    ? 'Activated'
                    : 'Not Activated';
            })
            .catch(() => {
                // Disabled toast on PROD/TEST
                if (this.environment.debug) Toast.error('XChange Box info could not be loaded for threat');
            });
    }
}
